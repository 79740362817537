import { AxiosResponse } from 'axios'
import { Content } from 'components/TasksV2/TaskCreation/FormStepContext'
import { httpAuth } from 'config/apiClient'
import endpoints from 'constants/endpoints'
import {
  IConfigGroup,
  ITag,
  Result,
  Results,
  RootState,
  Subtasks,
  Subtask as SubtaskV2,
  Task,
} from 'constants/interfaces'
import keys from 'constants/keys'
import { isNumber } from 'lodash'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { extractedUidsFromGroups, extractSubstringAfterDelimiter, getUTCOffset } from 'utils'

export interface TaskCreationBody {
  groups?: string[]
  users?: string[]
  tags?: string[] | null
  from_ts: string
  to_ts: string
  deadline: string
  utc_offset: number
  send_schedule_push: boolean
  send_campaign_start_push: boolean
  data: PollData
  sub_tasks: (PollSubTask | Subtask)[]
}

export interface EditTaskBody {
  tid: string
  utc_offset: number
  updated_to_ts: string
  updated_deadline: string
  updated_task_users: string[]
  updated_task_groups: string[]
  updated_task_tags: string[]
  task_data: {
    title: string
    view: number
    allowed_overdue_days: null
    contents?: Content[]
  }
}

interface PollData {
  title: string
  is_recurring: boolean
  recurrence_frequency: null | number
  recurrence_days_of_week: number | number[] | null
  categories: string[]
  view: number | null
  type: number
  is_individual_results: boolean
  recurrence_start_time: string | null
  contents?: Content[]
}
export interface Subtask {
  title: string
  type: number
  external_link?: string
}

export interface PollSubTask {
  title: string
  type: number
  is_multi_choice: boolean
  options: {
    [key: string]: string
  }
}

interface Response {
  data_columns: string[]
  data_rows: any[][]
}

export type SubtaskDeepDiveItem = {
  st_id: string
  type: number
  status: number
  context_id: string
  title: string
}

export interface TransformedDataWithCounts {
  [context_id: string]: {
    items: SubtaskDeepDiveItem[]
    typeCounts: { [type: number]: number }
  }
}
export type DeepDiveInsights = {
  contexts: {
    [key: string]: {
      status: number
      taskContextId: string
    }
  }
  insights: TransformedDataWithCounts
}

export type TasksInsights = Record<string, TaskFromInsights>

export type RecurrenceTsTaskInsights = {
  recurrence_deadline: string
  recurrence_ts: string
}
export type TaskInsightsResult = {
  context_id: string
  recurrence_ts: string | null
  st_id: string
  status: number
}

export type TaskFromInsights = {
  title: string
  desc: string | null
  from_ts: string
  deadline: string
  to_ts: string
  created_by: string
  view: number
  is_recurring: boolean
  recurrence_frequency: number
  recurrence_interval: number
  recurrence_days_of_week: number[] | null
  is_individual_results: boolean
  users: string[]
  groups: string[]
  tags: string[]
  sub_tasks: {
    [key: string]: string
  }
  recurrence_ts: RecurrenceTsTaskInsights[]
  recurrence_label: number | null
  results: TaskInsightsResult[]
}

export const actionTypes = {
  CREATE_TASK: 'CREATE_TASKS',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_SUBTASKS: 'SET_SUBTASKS',
  SET_TASKS: 'SET_TASKS',
  SET_POLL_RESULTS: 'SET_POLL_RESULTS',
  SET_USER_RESULTS: 'SET_USER_RESULTS',
  SET_ALL_RESULTS: 'SET_ALL_RESULTS',
  SET_TASKS_DEEP_DIVE_INSIGHTS: 'SET_TASKS_DEEP_DIVE_INSIGHTS',
  SET_TASKS_INSIGHTS: 'SET_TASKS_INSIGHTS',
  SET_FULL_TASK_FROM_INSIGHTS: 'SET_FULL_TASK_FROM_INSIGHTS',
  SET_IS_INSIGHTS_LOADING: 'SET_IS_INSIGHTS_LOADING',
  SET_ALL_RESULTS_LOADING: 'SET_ALL_RESULTS_LOADING',
}

export const setIsLoading = (isLoading: boolean) => ({
  type: actionTypes.SET_IS_LOADING,
  payload: isLoading,
})

export const setInsightsLoading = (isLoading: boolean) => ({
  type: actionTypes.SET_IS_INSIGHTS_LOADING,
  payload: isLoading,
})

export const setAllResultsLoading = (isLoading: boolean) => ({
  type: actionTypes.SET_ALL_RESULTS_LOADING,
  payload: isLoading,
})

export const setSubtasks = (subtasks: Subtasks) => ({
  type: actionTypes.SET_SUBTASKS,
  payload: subtasks,
})

export const setTasks = (tasks: Task[]) => ({
  type: actionTypes.SET_TASKS,
  payload: tasks,
})

export const setPollResults = (pollResults: Results) => ({
  type: actionTypes.SET_POLL_RESULTS,
  payload: pollResults,
})

export const setUserResults = (userResults: Results) => ({
  type: actionTypes.SET_USER_RESULTS,
  payload: userResults,
})

export const setAllResults = (allResults: Results) => ({
  type: actionTypes.SET_ALL_RESULTS,
  payload: allResults,
})
export const setOtherUsersResults = (otherResults: Results) => ({
  type: actionTypes.SET_USER_RESULTS,
  payload: otherResults,
})

export const setTasksDeepDiveInsights = (deepDiveInsights: DeepDiveInsights) => ({
  type: actionTypes.SET_TASKS_DEEP_DIVE_INSIGHTS,
  payload: deepDiveInsights,
})

export const setTasksInsights = (insights: TasksInsights) => ({
  type: actionTypes.SET_TASKS_INSIGHTS,
  payload: insights,
})
export const setFullTaskFromInsights = (task: Task) => ({
  type: actionTypes.SET_FULL_TASK_FROM_INSIGHTS,
  payload: task,
})

export const createTask =
  (data: TaskCreationBody): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const response = await httpAuth.post(endpoints.tasksV2, data)
      return !!response
    } catch (e) {
      console.log(e)
      return false
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const getTasks =
  (): ThunkAction<Promise<Results | null>, RootState, unknown, AnyAction> => async (dispatch, getState) => {
    const { activeGroupID } = getState().config
    const tags: string[] = []
    try {
      dispatch(setIsLoading(true))
      const response = await httpAuth.get(endpoints.tasksV2, {
        params: {
          context_id: activeGroupID,
          utc_offset: getUTCOffset(),
          ...(tags.length > 0 ? { tags } : {}),
        },
      })
      const { data } = response
      dispatch(setSubtasks(data.sub_tasks))
      dispatch(setTasks(data.tasks))
      dispatch(setUserResults(data.results))
      dispatch(setAllResults(data.results))

      return data.results
    } catch (e) {
      console.log(e)
      return null
    } finally {
      dispatch(setIsLoading(false))
    }
  }

interface UpdatePollResultsBody {
  tid: string
  st_id: string
  status: number
  type: number
  votes?: number[]
  images?: string[]
  videos?: string[]
  audios?: string[]
  files?: string[]
  resultContextId: string
  isExternalLink?: boolean
  isNumeric?: boolean
  answer?: string | number | null
  useOptimisticOnly?: boolean
}
const updateResultsArray = (results: Result[], updatedResult: Result): Result[] => {
  const index = results.findIndex(
    (result) => result.st_id === updatedResult.st_id && result.context_id === updatedResult.context_id
  )

  if (index !== -1) {
    const newArray = [...results]
    newArray[index] = updatedResult
    return newArray
  }

  return [...results, updatedResult]
}

const buildSubtaskDataObject = (
  type: number,
  images?: string[],
  videos?: string[],
  files?: string[],
  votes?: number[],
  answer?: string | number | null,
  isNumeric?: boolean
) => {
  switch (type) {
    case keys.SUBTASK_TYPES.IMAGE_SUBTASK:
      return { images: images || [] }
    case keys.SUBTASK_TYPES.VIDEO_SUBTASKS:
      return { videos: videos || [] }
    case keys.SUBTASK_TYPES.UPLOAD_FILE_SUBTASK:
      return { files: files || [] }
    case keys.SUBTASK_TYPES.POLL_SUBTASK:
      return { poll_selections: votes || [] }
    case keys.SUBTASK_TYPES.OPEN_QUESTION:
      return { answer, is_numeric: isNumeric }
    default:
      return {}
  }
}
type calculateContextIDArgs = {
  recipient_type: number
  is_individual_results: boolean
  uid: string
  groupId: string
  tagId: string | null
  tags: ITag[]
  retail_id_context_id: string
  rid: string
  dashboardContextId?: string
}

type calculateContextID = {
  currentTag: ITag
  uid: string
  groupId: string
}
const calculateTagContextId = ({ currentTag, uid, groupId }: calculateContextID) => {
  const tagGroups = currentTag?.groups ?? []
  const tagUsers = currentTag?.users ?? []

  if (tagUsers.includes(uid)) {
    return uid
  }
  if (tagGroups.includes(groupId)) {
    return groupId
  }
  return uid
}
export const calculateResultContextID = ({
  recipient_type,
  is_individual_results,
  uid,
  groupId,
  retail_id_context_id,
  rid,
  tagId,
  tags,
  dashboardContextId,
}: calculateContextIDArgs) => {
  const userId = dashboardContextId || uid
  // if recipient type is user and  is_individual_results = false or individual_results = true
  if (recipient_type === 0 || is_individual_results) {
    return userId //return uid
  }
  // if recipient type is tag
  if (recipient_type === 2) {
    const currentTag = tags.find((tag) => tag.sk === tagId)
    return calculateTagContextId({ currentTag: currentTag!, uid: userId, groupId }) //return uid or groupId
  }
  // if recipient type is group
  return extractSubstringAfterDelimiter(retail_id_context_id, rid) //return groupID
}

interface getTaskAudienceArgs {
  retailTags: ITag[]
  groups: string[]
  users: string[]
  tags: string[]
  is_individual_results: boolean
  groupsObject: { [key: string]: IConfigGroup }
}

export const getTaskAudience = ({
  retailTags,
  groups,
  users,
  tags,
  is_individual_results,
  groupsObject,
}: getTaskAudienceArgs) => {
  const filteredRetailTags = retailTags.filter((tag) => tags.includes(tag.sk))
  const tagsGroups = filteredRetailTags.map((tag) => tag?.groups ?? []).flat()
  const tagsUsers = filteredRetailTags.map((tag) => tag?.users ?? []).flat()

  const mergedGroups = Array.from(new Set([...groups, ...tagsGroups]))
  const mergedUsers = Array.from(new Set([...users, ...tagsUsers]))

  const groupsToPayload = is_individual_results ? [] : mergedGroups
  const usersToPayload = is_individual_results
    ? Array.from(new Set([...extractedUidsFromGroups(mergedGroups, groupsObject), ...mergedUsers]))
    : mergedUsers

  return { groups: groupsToPayload, users: usersToPayload }
}

export const updateResults =
  ({
    tid,
    votes = [],
    status,
    images = [],
    videos = [],
    files = [],
    st_id,
    type,
    resultContextId,
    isExternalLink,
    isNumeric,
    answer,
    useOptimisticOnly = false,
  }: UpdatePollResultsBody): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { tasks } = getState().tasks_v2
    const { uid } = getState().auth
    const {
      activeGroupID,
      config: { rid },
    } = getState().config
    const { allResults, userResults } = getState().tasks_v2
    const currentTask = tasks.find((task) => task.tid === tid)
    const currentSubtask = getState().tasks_v2.subtasks[tid].find((subtask) => subtask.st_id === st_id)

    const userResultOfCurrentSubtask = allResults?.[tid]?.find(
      (result) => result.st_id === st_id && result.context_id === resultContextId && result.status !== 0
    )

    const isFirstResult = !userResultOfCurrentSubtask

    const contextIdRecurrenceTs = `${resultContextId}_${currentTask?.recurrence_ts}`
    const updatedCurrentResult = {
      sub_task_type: type,
      st_id,
      status: status,
      context_id: resultContextId,
      context_id_recurrence_ts: isFirstResult
        ? contextIdRecurrenceTs
        : userResultOfCurrentSubtask.context_id_recurrence_ts,
      ...(type === keys.SUBTASK_TYPES.POLL_SUBTASK
        ? { is_multi_choice: currentSubtask?.is_multi_choice ?? false }
        : {}),
      updated_by: uid,
      updated_at_ts: Math.floor(Date.now() / 1000),
      ...buildSubtaskDataObject(type, images, videos, files, votes, answer, isNumeric),
    }

    const updatedUserResults: Results = {
      ...userResults,
      [tid]: updateResultsArray(userResults?.[tid] || [], updatedCurrentResult),
    }

    const updatedAllResults: Results = {
      ...allResults,
      [tid]: updateResultsArray(allResults?.[tid] || [], updatedCurrentResult),
    }

    dispatch(setUserResults(updatedUserResults))
    dispatch(setAllResults(updatedAllResults))

    if (isExternalLink || useOptimisticOnly) {
      return true
    }

    const data = {
      st_id,
      tid,
      task_context_id: extractSubstringAfterDelimiter(currentTask?.retail_id_context_id!, rid),
      context_id: activeGroupID,
      is_individual_results: currentTask?.is_individual_results,
      recurrence_ts: currentTask?.recurrence_ts ?? null,
      recurrence_start_time: currentTask?.is_recurring ? currentTask?.recurrence_start_time : null,
      utc_offset: getUTCOffset(),
      is_first_result: isFirstResult,
      recipient_type: currentTask?.recipient_type,
      data: {
        sub_task_type: type,
        status: status,
        ...(currentSubtask?.type === keys.SUBTASK_V2_TYPES.POLL_SUBTASK
          ? { is_multi_choice: currentSubtask?.is_multi_choice ?? false }
          : {}),
        ...buildSubtaskDataObject(type, images, videos, files, votes, answer, isNumeric),
      },
    }

    try {
      const response = await httpAuth.post(endpoints.updateResults, data)
      return !!response
    } catch (e) {
      console.log(e)
      return false
    }
  }

export const deleteTask =
  ({ tid }: { tid: string }): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(setIsLoading(true))

    const tasks = getState().tasks_v2.tasks
    const updatedTasks = tasks.filter((task) => task.tid !== tid)
    dispatch(setTasks(updatedTasks))

    const isDeleteAll = true
    const config = {
      data: {
        tid,
        is_delete_all: isDeleteAll,
      },
    }

    try {
      const response = await httpAuth.delete(endpoints.tasksV2, config)
      return !!response
    } catch (e) {
      console.log(e)
      return false
    } finally {
      dispatch(setIsLoading(false))
    }
  }

type GetResultsArgs = {
  subtasksIds: string[]
  task: Task
}
export const getAllResults =
  ({ subtasksIds, task }: GetResultsArgs): ThunkAction<Promise<Results | null>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { tasks_v2, config } = getState()
    const { allResults } = tasks_v2
    const { activeGroupID } = config

    try {
      dispatch(setAllResultsLoading(true))

      if (!task) {
        return null
      }
      const { tid } = task

      const body = {
        recurrence_ts: task.recurrence_ts ?? null,
        sub_tasks_ids: subtasksIds,
        recurrence_interval: task.recurrence_interval ?? null,
        recurrence_days_of_week: task.recurrence_days_of_week ?? null,
        from_ts: task.from_ts ?? null,
        ...(isNumber(task.recurrence_frequency) ? { recurrence_frequency: task.recurrence_frequency } : {}),
      }

      const response: AxiosResponse<any> = await httpAuth.post(endpoints.getAllResults(activeGroupID!), body)
      const { data } = response

      const results: Result[] = subtasksIds.flatMap((id) => data[id] || [])

      const existingAllResults =
        allResults[tid]?.filter(
          (prevResult) =>
            !results.some((result) => result.st_id === prevResult.st_id && result.context_id === prevResult.context_id)
        ) || []

      const allResultsOfCurrentTask: Results = {
        ...allResults,
        [tid]: [...existingAllResults, ...results],
      }

      dispatch(setAllResults(allResultsOfCurrentTask))

      return allResultsOfCurrentTask
    } catch (e) {
      console.error(e)
      return null
    } finally {
      dispatch(setAllResultsLoading(false))
    }
  }

type GetSubtasksResultsOverviewArgs = {
  tid: string
  contextIds: string[]
  sub_tasks_ids: string[]
  recurrence_ts: string | null
  recurrence_frequency: number | null
  recurrence_interval: number | null
}

export const getSubtasksResultsOverview =
  ({
    tid,
    contextIds,
    sub_tasks_ids,
    recurrence_ts,
    recurrence_frequency,
    recurrence_interval,
  }: GetSubtasksResultsOverviewArgs): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { tasks_v2 } = getState()
    const { allResults } = tasks_v2
    dispatch(setIsLoading(true))
    try {
      const body = {
        contexts_ids: contextIds,
        tid: tid,
        recurrence_ts: recurrence_ts,
        recurrence_frequency: recurrence_frequency,
        recurrence_interval: recurrence_interval,
        sub_tasks_ids: sub_tasks_ids ?? [],
      }

      const response = await httpAuth.post(endpoints.getSubtasksResultsOverview, body)
      const { data } = response

      const results: Result[] = data ?? []

      const existingAllResults =
        allResults[tid]?.filter(
          (prevResult) =>
            !results.some((result) => result.st_id === prevResult.st_id && result.context_id === prevResult.context_id)
        ) || []

      const allResultsOfCurrentTask: Results = {
        ...allResults,
        [tid]: [...existingAllResults, ...results],
      }

      dispatch(setAllResults(allResultsOfCurrentTask))
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

const transformResponseWithCounts = (response: Response): TransformedDataWithCounts => {
  try {
    const { data_columns, data_rows } = response
    if (!data_columns || !data_rows) {
      return {}
    }

    // Transform the response data into a structure with counts per type
    return data_rows.reduce<TransformedDataWithCounts>((acc, row) => {
      const rowObject = data_columns.reduce<any>((obj, column, index) => {
        obj[column] = row[index]
        return obj
      }, {})

      const contextId: string = rowObject.context_id
      const type: number = rowObject.type

      if (!acc[contextId]) {
        acc[contextId] = { items: [], typeCounts: {} }
      }

      // Push the row object into the items array
      acc[contextId].items.push(rowObject)

      // Count the occurrences of each type
      if (!acc[contextId].typeCounts[type]) {
        acc[contextId].typeCounts[type] = 0
      }
      acc[contextId].typeCounts[type] += 1

      return acc
    }, {})
  } catch (e) {
    console.error(e)
    return {}
  }
}

type CalculateContextIdsOfStatusesArgs = {
  is_individual_results: boolean
  groups: string[]
  users: string[]
  tags: string[]
  retailGroups: Record<string, IConfigGroup>
  retailTags: ITag[]
}
const calculateContextIdsOfStatuses = ({
  is_individual_results,
  groups,
  users,
  tags,
  retailGroups,
  retailTags,
}: CalculateContextIdsOfStatusesArgs): Record<string, string> => {
  const contextsIds: Record<string, string> = {}

  // Add users to contextsIds
  users.forEach((user) => {
    contextsIds[user] = contextsIds[user] || user
  })

  // Universal function to process groups
  const processGroup = (group: string, context: string) => {
    if (is_individual_results) {
      const groupUsers = extractedUidsFromGroups([group], retailGroups)
      groupUsers.forEach((user) => {
        contextsIds[user] = contextsIds[user] || context
      })
    } else {
      contextsIds[group] = contextsIds[group] || context
    }
  }

  // Process groups
  groups.forEach((group) => processGroup(group, group))

  // Process tags
  tags.forEach((tag) => {
    const tagData = retailTags.find((retailTag) => retailTag.sk === tag)
    if (!tagData) return

    // Process users associated with the tag
    tagData.users?.forEach((user) => {
      contextsIds[user] = contextsIds[user] || tag
    })

    // Process groups associated with the tag
    tagData.groups?.forEach((group) => processGroup(group, tag))
  })

  return contextsIds
}

export const getTasksInsights =
  ({
    tid,
    recurrence_ts,
  }: {
    tid: string
    recurrence_ts?: string | null
  }): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { insights } = getState().tasks_v2
    const {
      config: { groups: groupsObject, tags: retailTags },
    } = getState().config
    const { groups: permittedGroups, users: permittedUsers } = getState().config.userWriteTaskPermissions

    dispatch(setInsightsLoading(true))
    try {
      const currentTask = insights[tid]

      const { groups, users, tags, is_individual_results } = currentTask

      const taskContextsIds = calculateContextIdsOfStatuses({
        is_individual_results,
        groups,
        users,
        tags,
        retailGroups: groupsObject,
        retailTags,
      })

      const { groups: groupsToPayload, users: usersToPayload } = getTaskAudience({
        retailTags,
        groups,
        users,
        tags,
        is_individual_results,
        groupsObject,
      })

      const body = {
        tid,
        groups: groupsToPayload.filter((group) => Object.keys(permittedGroups).includes(group)) ?? [],
        users: usersToPayload.filter((user) => permittedUsers.includes(user)) ?? [],
        recurrence_ts: recurrence_ts ?? null,
      }

      const response = await httpAuth.post(endpoints.getTaskInsights, body)

      const transformedData = transformResponseWithCounts(response.data.insights)

      // Combine statuses and contextsIds
      const combinedStatuses = Object.entries(response.data.statuses).reduce((acc, [key, status]) => {
        const taskContextId = taskContextsIds[key]

        // Check if the status is a number before adding to the result
        if (taskContextId && typeof status === 'number') {
          acc[key] = { status, taskContextId }
        }

        return acc
      }, {} as Record<string, { status: number; taskContextId: string }>)

      const deepDiveInsights = {
        contexts: combinedStatuses,
        insights: transformedData,
      }
      dispatch(setTasksDeepDiveInsights(deepDiveInsights))

      return true
    } catch (e) {
      console.error(e)
      return false
    } finally {
      dispatch(setInsightsLoading(false))
    }
  }

type GetTasksInsightsParams = {
  from_ts?: string
  to_ts?: string
}

export const getInsightsV2 =
  ({ from_ts, to_ts }: GetTasksInsightsParams): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const activeGroupID = getState().config.activeGroupID
      dispatch(setInsightsLoading(true))
      const body = {
        from_ts,
        to_ts,
        context_id: activeGroupID,
        utc_offset: getUTCOffset(),
      }
      const response = await httpAuth.get(endpoints.getInsightsV2, { params: body })
      const { data } = response

      dispatch(setTasksInsights(data))
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setInsightsLoading(false))
    }
  }

type GetTaskParams = {
  tid: string
  task_context_id: string
  result_context_id: string
  recurrence_ts: string | null
}

type ReturnType = {
  task: Task
  subtasks: SubtaskV2[]
}
export const getTask =
  ({
    tid,
    task_context_id,
    result_context_id,
    recurrence_ts,
  }: GetTaskParams): ThunkAction<Promise<ReturnType>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { allResults, subtasks } = getState().tasks_v2
      const query = {
        tid,
        task_context_id,
        result_context_id,
        recurrence_ts,
      }
      const response = await httpAuth.get(endpoints.getTaskV2, { params: query })

      const { data } = response

      const results: Result[] = data.results?.[tid] ?? []

      const existingAllResults =
        allResults[tid]?.filter(
          (prevResult) =>
            !results.some((result) => result.st_id === prevResult.st_id && result.context_id === prevResult.context_id)
        ) || []

      const allResultsOfCurrentTask: Results = {
        ...allResults,
        [tid]: [...existingAllResults, ...results],
      }

      const task = { ...data.task, dashboardContextId: result_context_id }

      dispatch(setFullTaskFromInsights(task))
      dispatch(setSubtasks({ ...subtasks, ...data.sub_tasks }))

      dispatch(setAllResults(allResultsOfCurrentTask))
      return { task: data.task, subtasks: data.sub_tasks?.[tid] }
    } catch (e) {
      console.error(e)
      return { task: null, subtasks: [] }
    }
  }

const transformData = (data: SubtaskV2[]): Subtasks => {
  return data.reduce((acc, item) => {
    if (!acc[item.tid]) {
      acc[item.tid] = []
    }
    acc[item.tid].push(item)
    return acc
  }, {} as Subtasks)
}

export const getTaskData =
  ({ tid }: { tid: string }): ThunkAction<Promise<ReturnType | null>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { subtasks } = getState().tasks_v2
      const query = {
        tid,
      }
      const response = await httpAuth.get(endpoints.getTaskData, { params: query })
      const { data } = response
      const transformedSubtasks = transformData(data.sub_tasks)

      dispatch(setFullTaskFromInsights(data.task_data))
      dispatch(setSubtasks({ ...subtasks, ...transformedSubtasks }))
      return { task: data.task_data, subtasks: data.sub_tasks }
    } catch (e) {
      console.error(e)
      return null
    }
  }

export const editTask =
  (body: EditTaskBody): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { tasks, insights } = getState().tasks_v2
    dispatch(setIsLoading(true))
    try {
      const task = tasks.find((task) => task.tid === body.tid)
      const taskInsights = insights[body.tid]

      if (task) {
        task.deadline = body.updated_deadline
        task.to_ts = body.updated_to_ts
        task.users = body.updated_task_users
        task.groups = body.updated_task_groups
        task.tags = body.updated_task_tags
        task.view = body.task_data.view
        task.title = body.task_data.title

        if (body.task_data.contents && body.task_data.contents.length > 0) {
          task.contents = body.task_data.contents
        }
        const updatedTasks = tasks.map((prevTask) => (prevTask.tid === body.tid ? { ...prevTask, ...task } : prevTask))
        dispatch(setTasks(updatedTasks))
      }

      if (taskInsights) {
        taskInsights.deadline = body.updated_deadline
        taskInsights.to_ts = body.updated_to_ts
        taskInsights.groups = body.updated_task_groups
        taskInsights.users = body.updated_task_users
        taskInsights.tags = body.updated_task_tags
        taskInsights.title = body.task_data.title
        taskInsights.view = body.task_data.view

        const updatedInsights = {
          ...insights,
          [body.tid]: taskInsights,
        }
        dispatch(setTasksInsights(updatedInsights))
      }
      const response = await httpAuth.put(endpoints.tasksV2, { ...body })
      return response.status === 200
    } catch (e) {
      console.error(e)
      return false
    } finally {
      dispatch(setIsLoading(false))
    }
  }

type GetResultsOfRecurringTaskInsightsArgs = {
  tid: string
  context_id: string
  recurrence_ts: string[]
}
export const getResultsOfRecurringTaskInsights =
  ({
    tid,
    context_id,
    recurrence_ts,
  }: GetResultsOfRecurringTaskInsightsArgs): ThunkAction<
    Promise<TaskInsightsResult[] | null>,
    RootState,
    unknown,
    AnyAction
  > =>
  async (dispatch) => {
    try {
      const query = {
        tid,
        context_id,
        recurrence_ts: recurrence_ts.join(','),
      }
      const result = await httpAuth.get(endpoints.getResultsOfRecurringTaskInsights, { params: query })
      const { data } = result
      if (data) {
        return data
      }
    } catch (e) {
      console.error(e)
      return null
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export interface IResetResults {
  tid: string
  sub_tasks_ids: string[]
  result_context_id: string
  recurrence_ts?: string
}

export const resetResults =
  ({
    tid,
    sub_tasks_ids,
    result_context_id,
    recurrence_ts,
  }: IResetResults): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { taskFromInsights } = getState().tasks_v2
    const { rid } = getState().config.config

    const task_context_id = extractSubstringAfterDelimiter(taskFromInsights?.retail_id_context_id!, rid)

    const config = {
      data: {
        tid,
        sub_tasks_ids,
        task_context_id,
        result_context_id,
        recurrence_ts,
      },
    }
    const results = await httpAuth.delete(endpoints.resetResults, config)

    if (results.status === 200) {
      return true
    }
    return false
  }
