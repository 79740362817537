import { Box, Flex, Text } from '@chakra-ui/core'
import { StatusBlock } from 'components/TasksV2/UI/StatusBlock'
import useLocaleText from 'components/useLocaleText'
import colors from 'constants/colors'
import keys from 'constants/keys'
import { AlarmClockCheck, ChartSpline, CircleCheckBig } from 'lucide-react'
import React, { memo, useEffect, useState } from 'react'
import { DeepDiveInsights } from 'redux/actions/tasks_v2'

export const calculateTaskStats = (tasks: Record<string, { status: number; taskContextId: string }>): TaskStats => {
  const stats: TaskStats = {
    totalTasks: 0,
    statuses: {
      todo: 0,
      inProgress: 0,
      done: 0,
    },
  }

  Object.values(tasks).forEach((task) => {
    stats.totalTasks += 1
    if (task.status === keys.TASK_STATUSES.TODO.value) stats.statuses.todo += 1
    else if (task.status === keys.TASK_STATUSES.INPROGRESS.value) stats.statuses.inProgress += 1
    else if (
      task.status === keys.TASK_STATUSES.DONE.value ||
      task.status === keys.TASK_STATUSES.SKIPPED.value ||
      task.status === -1
    )
      stats.statuses.done += 1
  })

  return stats
}

interface TaskStatusItemProps {
  title: string
  count: number
  bgColor: string
  width: string
  padding: string
  children?: React.ReactNode
  isEmpty?: boolean
  symbol?: string
  isShowIcons: boolean
  isLoaded?: boolean
  icon: React.ReactNode
}
const TaskStatusItem: React.FC<TaskStatusItemProps> = ({
  title,
  count,
  bgColor,
  width,
  padding,
  children,
  isEmpty,
  symbol,
  isLoaded,
  isShowIcons,
  icon,
}) => {
  const [animatedCount, setAnimatedCount] = useState(0)

  useEffect(() => {
    if (!isEmpty) {
      let currentCount = 0
      const step = Math.ceil(count / 50)
      const interval = setInterval(() => {
        currentCount += step
        if (currentCount >= count) {
          setAnimatedCount(count)
          clearInterval(interval)
        } else {
          setAnimatedCount(currentCount)
        }
      }, 20)

      return () => clearInterval(interval)
    }
  }, [count, isEmpty])

  return (
    <StatusBlock
      background={bgColor}
      width={width}
      p={padding}
      style={{
        transform: isLoaded ? 'scaleY(1)' : 'scaleY(0.6)',
        height: isLoaded ? 'auto' : '20px',
        transition: 'all 0.5s ease-in-out',
        overflow: 'hidden',
      }}
    >
      {!isEmpty && (
        <>
          {title && <Text fontSize="14px">{title}</Text>}
          <Flex alignItems="center" w="100%" justifyContent="space-between">
            <Text fontSize="36px" fontWeight="bold">
              {animatedCount}
              {symbol}
            </Text>
            {children}
            {isShowIcons && (
              <Box borderRadius="full" background="white" p="12px">
                {icon}
              </Box>
            )}
          </Flex>
        </>
      )}
    </StatusBlock>
  )
}

type TaskStats = {
  totalTasks: number
  statuses: { todo: number; inProgress: number; done: number }
}
interface TaskStatusesBlocksProps {
  isEmpty?: boolean
  deepDiveInsights?: DeepDiveInsights
  isShowIcons?: boolean
}

export const TaskStatusesBlocks: React.FC<TaskStatusesBlocksProps> = memo(
  ({ deepDiveInsights, isEmpty = false, isShowIcons = false }) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const tasksStatuses = calculateTaskStats(deepDiveInsights?.contexts || {})
    const task_status_done = useLocaleText('task_status_done')
    const task_status_inprogress = useLocaleText('task_status_inprogress')
    const task_status_todo = useLocaleText('task_status_todo')
    const statusItems = [
      {
        key: 'todo',
        title: task_status_todo,
        color: colors.analyticsGreyLight,
        width: '100%',
        icon: <AlarmClockCheck size={18} />,
      },
      {
        key: 'inProgress',
        title: task_status_inprogress,
        color: colors.analyticYellow,
        width: '100%',
        icon: <ChartSpline size={18} />,
      },
      {
        key: 'done',
        title: task_status_done,
        color: colors.analyticGreen,
        width: '100%',
        icon: <CircleCheckBig size={18} />,
      },
    ]
    useEffect(() => {
      if (!isEmpty) {
        const timer = setTimeout(() => setIsLoaded(true), 0)
        return () => clearTimeout(timer)
      } else {
        setIsLoaded(false)
      }
    }, [isEmpty])

    return (
      <Flex alignItems="center" style={{ columnGap: '20px' }} w="100%" my="20px">
        {statusItems.map(({ key, title, color, icon, width }) => (
          <TaskStatusItem
            key={key}
            icon={icon}
            isShowIcons={isShowIcons}
            title={title}
            count={tasksStatuses?.statuses[key as keyof TaskStats['statuses']]}
            bgColor={color}
            width={width}
            padding={isEmpty ? '10px' : isLoaded ? '20px' : '10px'}
            isEmpty={isEmpty}
            isLoaded={isLoaded}
            symbol={key === 'totalTasks' ? '%' : ''}
          />
        ))}
      </Flex>
    )
  }
)
