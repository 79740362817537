import React from 'react'
import { Box, Text, Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as MenuFeedIcon } from 'assets/menu-feed.svg'
import { ReactComponent as MenuReportsIcon } from 'assets/menu-reports.svg'
import { ReactComponent as MenuTasksAutoIcon } from 'assets/menu-tasks-auto.svg'
import { ReactComponent as MenuTasksDashboardIcon } from 'assets/menu-tasks-dashboard.svg'
import { ReactComponent as JotForm } from 'assets/form.svg'

import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import keys from 'constants/keys'
import { RootState } from 'constants/interfaces'
import { ComposeButton } from 'components/Sidebar/ComposeButton'
import { GroupAvatars } from 'components/CommonComponents/GroupAvatars'
import { CreateTaskV2Button } from 'components/Sidebar/CreateTaskV2Button'
import { ChooseContextButton } from './ChooseContextButton'
import { getInsightsV2 } from 'redux/actions/tasks_v2'
import { getInitialData, setDateFilter } from 'redux/actions/tasksOverview'
import { setDateFilter as setDateFilterServiceCall } from 'redux/actions/serviceCall'
import { CreateServiceCallButton } from './CreateServiceCallButton'
import { getServiceCallsDashboard } from 'redux/actions/serviceCall'

const allowedUIDs = [
  '033750035',
  '302632377',
  '039267885',
  '200928497',
  '032817942',
  '204110902',
  '032472268',
  '040044406',
  '040490039',
  '305035560',
  '300095973',
  '037811171',
  '206016438',
  '306016478',
  '203893151',
  '039727433',
  '300170503',
  'sto-max',
  'sto-tra',
  '0523202400',
  '0523991103',
  '0526653810',
  '0523685333',
  '0504840002',
  '0523685372',
  '0522416770',
  '0542204190',
  '0545704250',
  '0526891122',
  '0544832276',
  '0502152299',
  '0526870641',
  '0508626916',
  '0526873555',
  '0524818514',
  '0523991072',
]

interface IMenuItem {
  route: string
  title: string
  icon: React.ReactChild
  isHide: boolean
  id: string
  color?: string
  onClick?: () => void
}

export const IconBlock = styled(Flex)`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`
export const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  @media (max-width: 1000px) {
    padding: 5px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
  }
`

const NavHeader = styled(Text)`
  margin: 10px 0;
  color: #6b6b6b;
  font-size: 14px;
  font-weight: 500;
`

const NavMenu: React.FC<{ setIsHidden?: (value: boolean) => void }> = ({ setIsHidden }) => {
  const dispatch = useDispatch()
  const { isLoginByLink, uid } = useSelector((state: RootState) => state.auth)
  const {
    activeGroupID,
    retailConfig,
    config: { groups },
  } = useSelector((state: RootState) => state.config)
  const { isFetchingData } = useSelector((state: RootState) => state.tasksOverview)
  const { isInsightsLoading } = useSelector((state: RootState) => state.tasks_v2)
  const { isLoading } = useSelector((state: RootState) => state.serviceCalls)
  const location = useLocation()
  const { targetGroupId } = useParams()
  const currentRoute = location.pathname

  const t_newsfeed_sidebar = useLocaleText('newsfeed_sidebar')
  const t_reports_sidebar = useLocaleText('reports_sidebar')
  const t_tasks_dashboard = useLocaleText('tasks_dashboard')
  const t_analytics_header = useLocaleText('analytics_header')
  const t_tasks_header = useLocaleText('tasks_header')
  const t_tasks = useLocaleText('sidebar_tasks_page')
  const t_digital_papers = useLocaleText('digital_papers')
  const t_service_calls_dashboard = useLocaleText('service_calls_dashboard')

  const renderMenu = (menuList: IMenuItem[]) => {
    return menuList.map((item) => {
      if (item.isHide) {
        return null
      }
      return (
        <NavItem
          onClick={item.onClick}
          to={
            item.route === keys.ROUTE_NAMES.REPORTS && activeGroupID
              ? `${keys.ROUTE_NAMES.REPORTS}/${activeGroupID}`
              : item.route
          }
          key={item.route}
          style={({ isActive }) => ({
            fontWeight: isActive ? 'bold' : 'inherit',
          })}
          id={item.id}
        >
          <IconBlock background={item.color || 'transparent'}>{item.icon}</IconBlock>
          <Text mx="10px" textTransform="capitalize">
            <LocaleText text={item.title} />
          </Text>
        </NavItem>
      )
    })
  }

  const mainItems: IMenuItem[] = [
    {
      route: keys.ROUTE_NAMES.NEWS_FEED,
      isHide: false,
      title: t_newsfeed_sidebar,
      icon: <MenuFeedIcon />,
      id: 'nav_btn_0',
    },
  ]

  const analyticsMenu: IMenuItem[] = [
    {
      route: keys.ROUTE_NAMES.REPORTS,
      isHide: !!isLoginByLink,
      title: t_reports_sidebar,
      icon: <MenuReportsIcon />,
      id: 'nav_btn_4',
    },
    {
      route: keys.ROUTE_NAMES.DASHBOARD,
      isHide: false,
      title: t_tasks_dashboard,
      icon: <MenuTasksDashboardIcon />,
      id: 'nav_btn_3',
      onClick: () => {
        if (!isInsightsLoading || !isFetchingData) {
          dispatch(setDateFilter(null))
          dispatch(getInsightsV2({}))
          dispatch(getInitialData(groups, activeGroupID, undefined, undefined, true))
        }
      },
    },
    {
      route: keys.ROUTE_NAMES.SERVICE_CALLS_DASHBOARD,
      isHide: false,
      title: t_service_calls_dashboard,
      icon: <MenuTasksDashboardIcon />,
      id: 'nav_btn_5',
      onClick: () => {
        if (!isLoading) {
          setDateFilterServiceCall(null)
          dispatch(getServiceCallsDashboard({ context_id: activeGroupID! }))
        }
      },
    },
  ]

  const tasksMenu: IMenuItem[] = [
    {
      route: keys.ROUTE_NAMES.HUB,
      title: t_tasks,
      icon: <MenuTasksAutoIcon />,
      id: 'nav_btn_6',
      isHide: false,
    },
  ]

  const otherItems: IMenuItem[] = [
    {
      route: keys.ROUTE_NAMES.EXTERNAL_SITE,
      title: t_digital_papers,
      id: 'nav_btn_6',
      isHide: false,
      icon: <JotForm />,
    },
  ]
  return (
    <Box
      as="nav"
      mt={{
        lg: '38px',
        base: '0',
      }}
    >
      {currentRoute.includes(keys.ROUTE_NAMES.REPORTS) && retailConfig?.is_personalization_mode && targetGroupId && (
        <GroupAvatars group={targetGroupId} />
      )}
      <Box pt="10px" pb="26px">
        <ChooseContextButton setIsHidden={setIsHidden} />
      </Box>

      <Flex flexDir="column" style={{ rowGap: '15px' }} p="10px 0">
        <ComposeButton setIsHidden={setIsHidden} />
        <CreateTaskV2Button setIsHidden={setIsHidden} />
        <CreateServiceCallButton setIsHidden={setIsHidden} />
      </Flex>

      <NavHeader style={{ margin: '30px  0 10px 0' }}>{t_newsfeed_sidebar}</NavHeader>
      {renderMenu(mainItems)}
      <NavHeader>{t_tasks_header}</NavHeader>
      {renderMenu(tasksMenu)}
      <NavHeader>{t_analytics_header}</NavHeader>
      {renderMenu(analyticsMenu)}
      {allowedUIDs.includes(uid!) && renderMenu(otherItems)}
    </Box>
  )
}

export default NavMenu
