import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { Stack, Flex, Input, Text, Box, useToast } from '@chakra-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { NoFocusButton } from 'components/CommonComponents/NoFocusButton'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import { CountdownTimer } from 'components/CommonComponents/CountdownTimer'
import { confirmOTPCode, ConfirmOTPCodeResponse, getOTP } from 'redux/actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'config/redux'
import { RootState } from 'constants/interfaces'
import { useNavigate } from 'react-router-dom'
type FormValues = {
  code_0: string
  code_1: string
  code_2: string
  code_3: string
  code_4: string
  code_5: string
}

interface ConfirmCodeProps {
  handleOtpAction: (data: ConfirmOTPCodeResponse) => void
  navigateToScreen: (data: any) => void
  contactInfo?: {
    email?: string
    phone_number?: string
    country_code?: string
  }
}

const CODE_LENGTH = 6
const MAX_ATTEMPTS = 3

export const ConfirmCode: React.FC<ConfirmCodeProps> = ({ handleOtpAction, navigateToScreen, contactInfo }) => {
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useToast()
  const { handleSubmit, control, getValues, setValue, reset } = useForm<FormValues>({
    defaultValues: {
      code_0: '',
      code_1: '',
      code_2: '',
      code_3: '',
      code_4: '',
      code_5: '',
    },
    mode: 'onSubmit',
  })

  const { isLoginLoading } = useSelector((state: RootState) => state.auth)
  const [error, setError] = useState<string | null>(null)
  const [attempts, setAttempts] = useState<number>(0)
  const [isBlocked, setIsBlocked] = useState<boolean>(false)

  const inputsRef = useRef<HTMLInputElement[]>([])
  const timerKey = useRef<number>(0)

  const verify_code = useLocaleText('verify_code')
  const resend_code = useLocaleText('resend_code')
  const start_over = useLocaleText('start_over')
  const code_sent_to = useLocaleText('code_sent_to')
  const invalid_code = useLocaleText('invalid_code')
  const phone_or_email = useLocaleText('phone_or_email_placeholder')
  const max_attempts_reached = useLocaleText('max_attempts_reached')
  const attempts_remaining = useLocaleText('attempts_remaining')
  const invalid_code_error = useLocaleText('invalid_code_error')

  const watchedValues = useMemo(() => getValues(), [getValues])

  const handleChange = useCallback(
    (index: number, value: string) => {
      if (isBlocked) return

      const nextValue = value.replace(/\D/g, '').slice(0, 1)
      setValue(`code_${index}`, nextValue)

      if (nextValue && index < CODE_LENGTH - 1) {
        inputsRef.current[index + 1]?.focus()
      }
    },
    [isBlocked, setValue]
  )

  const handleKeyDown = useCallback(
    (index: number, e: React.KeyboardEvent) => {
      switch (e.key) {
        case 'Backspace':
          if (!getValues(`code_${index}`) && index > 0) {
            inputsRef.current[index - 1]?.focus()
          }
          break
        case 'ArrowLeft':
          if (index > 0) inputsRef.current[index - 1]?.focus()
          break
        case 'ArrowRight':
          if (index < CODE_LENGTH - 1) inputsRef.current[index + 1]?.focus()
          break
      }
    },
    [getValues]
  )

  const handlePaste = useCallback(
    (e: React.ClipboardEvent, index: number) => {
      e.preventDefault()
      const pastedData = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, CODE_LENGTH)

      pastedData.split('').forEach((char, i) => {
        if (index + i < CODE_LENGTH) {
          setValue(`code_${index + i}`, char)
        }
      })

      inputsRef.current[Math.min(index + pastedData.length, CODE_LENGTH - 1)]?.focus()
    },
    [setValue]
  )

  const clearInputFields = useCallback(() => {
    reset()
    if (!isBlocked) inputsRef.current[0]?.focus()
  }, [reset, isBlocked])

  const handleBeforeInput = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const inputEvent = e as unknown as InputEvent
    const target = e.target as HTMLInputElement

    if (target.value.length > 0) {
      e.preventDefault()
      return
    }

    if (!/^\d$/.test(inputEvent.data || '')) {
      e.preventDefault()
    }
  }, [])

  const onSubmit = async (data: FormValues) => {
    const fullCode = Object.values(data).join('')
    if (fullCode.length !== 6) {
      setError(invalid_code)
      clearInputFields()
      return
    }
    setError(null)

    const response = await dispatch(confirmOTPCode({ otp: fullCode, ...contactInfo }))

    if (typeof response === 'boolean' && response) {
      navigate('/')
      return
    }

    if (typeof response === 'object' && response?.otp_token && response?.uids?.length > 0) {
      handleOtpAction(response)
      return
    }

    if (typeof response === 'number') {
      setAttempts((prev) => prev + 1)

      if (attempts === 2 && response === 401) {
        setIsBlocked(true)
        toast({
          description: max_attempts_reached,
          status: 'error',
          duration: 5000,
        })
      } else if (response === 422) {
        const remainingAttempts = MAX_ATTEMPTS - (attempts + 1)
        toast({
          description: `${invalid_code}. ${attempts_remaining}: ${remainingAttempts}`,
          status: 'warning',
        })
      } else {
        toast({
          description: invalid_code_error,
          status: 'error',
        })
      }
    }

    clearInputFields()
  }

  const getFormattedContactInfo = () => {
    if (contactInfo?.email) {
      return contactInfo.email
    } else if (contactInfo?.phone_number && contactInfo?.country_code) {
      return `+${contactInfo.country_code} ${contactInfo.phone_number}`
    }
    return phone_or_email
  }

  const handleResendCode = useCallback(async () => {
    if (!contactInfo) return

    timerKey.current += 1
    clearInputFields()
    setError(null)

    try {
      const response = await dispatch(getOTP(contactInfo?.email ? { email: contactInfo.email } : contactInfo))
      if (!response) throw new Error('Failed to send OTP')

      setIsBlocked(false)
      setAttempts(0)
    } catch (error) {
      toast({ title: 'Error', description: 'Failed to send OTP', status: 'error' })
    }
  }, [clearInputFields, contactInfo, dispatch, toast])

  const handleExpire = useCallback(() => {
    setIsBlocked(true)
  }, [])

  useEffect(() => {
    inputsRef.current.forEach((input, index) => {
      if (!input) return

      const handleInput = () => {
        const value = input.value.replace(/\D/g, '').slice(0, 1)

        if (value && index < 5 && inputsRef.current[index + 1]) {
          inputsRef.current[index + 1].focus()
        }
      }

      input.addEventListener('input', handleInput)

      return () => {
        input.removeEventListener('input', handleInput)
      }
    })
  }, [])

  return (
    <Stack spacing={6} pointerEvents={isLoginLoading ? 'none' : 'auto'} opacity={isLoginLoading ? 0.8 : 1}>
      <Text textAlign="center" fontSize="32px">
        <LocaleText text={verify_code} />
      </Text>

      <Text textAlign="center" color="gray.600">
        <LocaleText text={code_sent_to} /> {getFormattedContactInfo()}
      </Text>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex justifyContent="center" style={{ columnGap: '10px' }} mb="1.5rem">
          {Array.from({ length: CODE_LENGTH }).map((_, index) => (
            <Box key={index}>
              <Controller
                name={`code_${index}` as keyof FormValues}
                control={control}
                as={
                  <Input
                    ref={(el: HTMLInputElement | null) => {
                      if (el) inputsRef.current[index] = el
                    }}
                    autoFocus={index === 0}
                    maxLength={1}
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                    bg="white"
                    width="40px"
                    height="40px"
                    px={1}
                    type="text"
                    inputMode="numeric"
                    pattern="\d*"
                    borderRadius="8px"
                    border="1px solid"
                    borderColor={
                      error ? 'red.500' : watchedValues[`code_${index}` as keyof FormValues] ? 'black' : '#E2E8F0'
                    }
                    _focus={{
                      border: '1px solid black',
                      boxShadow: 'none',
                    }}
                    _hover={{
                      border: '1px solid black',
                    }}
                    onChange={(e) => {
                      handleChange(index, (e.target as HTMLInputElement).value)
                    }}
                    onBeforeInput={handleBeforeInput}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={(e) => handlePaste(e, index)}
                    isDisabled={isBlocked}
                  />
                }
              />
            </Box>
          ))}
        </Flex>

        <CountdownTimer
          key={timerKey.current}
          initialSeconds={300}
          onExpire={handleExpire}
          isActive={!isBlocked}
          timeRemainingText="time_remaining"
          expiredText="code_expired"
          isMaxAttemptsReached={attempts >= MAX_ATTEMPTS}
          maxAttemptsText="max_attempts_reached"
        />

        <NoFocusButton
          type="submit"
          width="100%"
          bg="#d53f8c"
          color="white"
          borderRadius="20px"
          height="40px"
          mt={4}
          isLoading={isLoginLoading}
          isDisabled={isLoginLoading || isBlocked}
        >
          <LocaleText text={verify_code} />
        </NoFocusButton>
      </form>

      <Flex direction="column" alignItems="center" mt={4}>
        <Text fontSize="sm" mb={2}>
          <LocaleText text="didnt_receive_code" />
        </Text>
        <Flex>
          <NoFocusButton variant="link" color="#d53f8c" mr={4} onClick={handleResendCode}>
            {resend_code}
          </NoFocusButton>

          <NoFocusButton
            variant="link"
            color="#d53f8c"
            onClick={() => {
              navigateToScreen({ screen: 'SEND_OTP' })
            }}
          >
            {start_over}
          </NoFocusButton>
        </Flex>
      </Flex>
    </Stack>
  )
}
