import React, { useState, useCallback } from 'react'
import { Text, Menu, MenuButton, MenuList, MenuItem, Flex, Box } from '@chakra-ui/core'
import { useToast } from '@chakra-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { NoFocusButton } from 'components/CommonComponents/NoFocusButton'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import { ChevronDown } from 'lucide-react'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { ConfirmOTPCodeResponse, loginOTP } from 'redux/actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'config/redux'
import { useNavigate } from 'react-router-dom'
import keys from 'constants/keys'
import { RootState } from 'constants/interfaces'
import { CountdownTimer } from 'components/CommonComponents/CountdownTimer'

interface PossibleUsersFormProps {
  usersList: ConfirmOTPCodeResponse
  navigateToScreen: (data: any) => void
}

type FormValues = {
  selectedUser: string
}

export const PossibleUsersForm: React.FC<PossibleUsersFormProps> = ({ usersList, navigateToScreen }) => {
  const { uids, otp_token } = usersList
  const toast = useToast()
  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch()
  const { handleSubmit, control, watch } = useForm<FormValues>({
    defaultValues: {
      selectedUser: uids.length > 0 ? uids[0] : '',
    },
    mode: 'onSubmit',
  })
  const { isLoginLoading } = useSelector((state: RootState) => state.auth)
  const selectedUser = watch('selectedUser')
  const select_user_title = useLocaleText('select_user')
  const login_error = useLocaleText('login_error')

  const [isActive, setIsActive] = useState<boolean>(true)

  const handleExpire = useCallback(() => {
    setIsActive(false)
  }, [])

  const handleFormSubmit = async (data: FormValues) => {
    const response = await dispatch(loginOTP({ otp_token, uid: data.selectedUser }))
    if (response) {
      navigate(keys.ROUTE_NAMES.NEWS_FEED)
    } else {
      toast({ description: login_error, status: 'error' })
    }
  }

  return (
    <>
      <Box
        pointerEvents={isLoginLoading || !isActive ? 'none' : 'auto'}
        opacity={isLoginLoading || !isActive ? 0.8 : 1}
        style={{ width: '100%' }}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)} style={{ width: '100%' }}>
          <Flex flexDir="column" w="100%" style={{ rowGap: '20px' }}>
            <Text textAlign="center" fontSize="32px" mb="20px">
              <LocaleText text={select_user_title} />
            </Text>
            <Controller
              name="selectedUser"
              control={control}
              as={
                <Flex flexDir="column" w="100%">
                  <Menu>
                    <MenuButton as={Flex} w="100%" style={{ columnGap: '10px' }} alignItems="center">
                      <WidgetWrapper p="10px" cursor="pointer" w="100%" flexDir="row" justifyContent="space-between">
                        <Text>{selectedUser}</Text>
                        <ChevronDown size={18} />
                      </WidgetWrapper>
                    </MenuButton>
                    <MenuList
                      borderColor="#E2E8F0"
                      boxShadow="0px 2px 8px rgba(0, 0, 0, 0.1)"
                      borderRadius="8px"
                      w="100%"
                      maxW="320px"
                      h="max-content"
                      maxH="400px"
                      overflowY="auto"
                    >
                      {uids.map((uid, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => control.setValue('selectedUser', uid)}
                          bg={selectedUser === uid ? '#f9f9f9' : 'white'}
                          _hover={{ bg: '#f0f0f0' }}
                          _focus={{ bg: '#f0f0f0' }}
                        >
                          <Text fontSize="md">{uid}</Text>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Flex>
              }
            />

            <CountdownTimer
              initialSeconds={300}
              onExpire={handleExpire}
              isActive={true}
              timeRemainingText="time_remaining"
              expiredText="time_expired"
            />

            <NoFocusButton
              isDisabled={!selectedUser || isLoginLoading || !isActive}
              isLoading={isLoginLoading}
              type="submit"
              width="100%"
              bg="#d53f8c"
              color="white"
              borderRadius="20px"
              height="40px"
            >
              <LocaleText text="login" />
            </NoFocusButton>
          </Flex>
        </form>
      </Box>
      <Flex justifyContent="center" mt={4}>
        <NoFocusButton
          variant="link"
          color="#d53f8c"
          onClick={() => {
            navigateToScreen({ screen: 'SEND_OTP' })
          }}
        >
          <LocaleText text="start_over" />
        </NoFocusButton>
      </Flex>
    </>
  )
}
